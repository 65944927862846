$niceColor: #57606f

//breakpoints
$break480: 480px
$break480M: $break480 - 1px
$break768: 768px
$break768M: $break768 - 1px
$break992: 992px
$break992M: $break992 - 1px
$break1200: 1200px
$break1200M: $break1200 - 1px
