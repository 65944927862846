@use 'common'

@font-face
	font-family: 'General Sans'
	src: url('/fonts/GeneralSansMedium/GeneralSans-Medium.woff2') format('woff2'), url('/fonts/GeneralSansMedium/GeneralSans–Medium.woff') format('woff')
	font-weight: 600
	font-style: normal

@font-face
	font-family: 'General Sans'
	src: url('/fonts/GeneralSansSemiBold/GeneralSans-Semibold.woff2') format('woff2'), url('/fonts/GeneralSansSemiBold/GeneralSans–Semibold.woff') format('woff')
	font-weight: 700

@font-face
	font-family: 'General Sans'
	src: url('/fonts/GeneralSansBold/GeneralSans-Bold.woff2') format('woff2'), url('/fonts/GeneralSans/GeneralSans-Bold.woff') format('woff')
	font-weight: 900
	font-style: bold

body
	background: #EFEDED
	color: common.$niceColor
	padding: 0
	margin: 0
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif
	@media screen and ( max-width: common.$break992 )
		overflow-y: scroll
		// body::-webkit-scrollbar
		// 	display: none

a
	color: inherit
	text-decoration: none

*
	box-sizing: border-box
	font-family: General Sans

.layout
	min-height: 100vh
	display: flex
	flex-direction: column
	&-main
		flex-grow: 1
